import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';

const unhydratedInitialState = {
  trackedMutationCount: 0,
  tenant: '',
  tenantLongName: '',
  tenantShortName: '',
  tenantHasReinspections: false,
  tenantHasQccInspections: false,
  versionCurrent: true,
  mutating: false,
  online: false,
  deviceOnline: false,
  serverOnline: false,
};

const initialState = {
  ...unhydratedInitialState,
  bootswatchStyle: 'sandstone',
  isOpenSidebar: true,
  listColumnFilters: {
    user: [],
    claim: [],
    claimItem: [],
    claimClaimImage: [],
    claimClaimAttachment: [],
    claimInspected: [],
    claimReinspection: [],
    claimQccInspection: [],
    claimInternalNote: [],
    oacItemPricing: [],
    oacReferral: [],
    workCenterItemPricing: [],
  },
  listPagination: {
    user: { pageIndex: 0, pageSize: 50 },
    claim: { pageIndex: 0, pageSize: 50 },
    claimItem: { pageIndex: 0, pageSize: 50 },
    claimClaimImage: { pageIndex: 0, pageSize: 50 },
    claimClaimAttachment: { pageIndex: 0, pageSize: 50 },
    claimInspected: { pageIndex: 0, pageSize: 50 },
    claimReinspection: { pageIndex: 0, pageSize: 50 },
    claimQccInspection: { pageIndex: 0, pageSize: 50 },
    claimInternalNote: { pageIndex: 0, pageSize: 50 },
    oacItemPricing: { pageIndex: 0, pageSize: 50 },
    oacReferral: { pageIndex: 0, pageSize: 50 },
    workCenterItemPricing: { pageIndex: 0, pageSize: 50 },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsSet(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('persist/REHYDRATE', (_, action) => {
        const persistedState = get(action, ['payload', 'settings'], {});
        const persistedListColumnFilters = get(persistedState, 'listColumnFilters', {});
        const persistedListPagination = get(persistedState, 'listPagination', {});
        return {
          ...initialState,
          ...persistedState,
          // stop rehydrate overwriting new table states
          listColumnFilters: {
            ...initialState.listColumnFilters,
            ...persistedListColumnFilters,
          },
          listPagination: {
            ...initialState.listPagination,
            ...persistedListPagination,
          },
          ...unhydratedInitialState,
        };
      })
      .addDefaultCase((state) => state);
  },
});

export const { settingsSet } = settingsSlice.actions;
export default settingsSlice.reducer;
